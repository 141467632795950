import { useRef, useEffect, useState } from "react";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { getUserSession } from "../../utils/storage";

const Header = () => {

  const [user, setUser] = useState();
  const [popUp, showPopUp] = useState(false);
  const popUpRef = useRef(null);
  const navigate = useNavigate();

  const togglePopUp = () => {
    showPopUp((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        setTimeout(() => {
          if (popUp) {
            showPopUp(false);
          }
        },100);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popUp]);

  useEffect(() => {
    const user = getUserSession();
    user.name ? setUser(user?.name) : setUser("Usuário");
  }, [])

  return (
    <S.Header>
      <div className="right">
        <a href="/"><img src="./home.svg" alt="Home" height={25} width={25}></img></a>
        <img src="/logotipo-inovally.png" alt="Inovally" height={25} width={90}></img>
      </div>
      <S.User onClick={() => { togglePopUp() }}>
        <img src="/user.svg" alt="Ícone user"></img>
        <h3>Olá, {user}</h3>
        {popUp && <S.PopUp ref={popUpRef}>
          <S.Line onClick={() => {navigate('/logout')}}>Sair</S.Line>
        </S.PopUp>}
      </S.User>

    </S.Header>
  )
}

export default Header;