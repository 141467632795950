import { Container, Title, Options, Option, HeaderWrap } from "../styles/dashboard";
import axios from "axios";
import Terms from "../components/terms";
import Prename from "../assets/img/logo_transparente.PNG";
import React, { useCallback, useEffect, useRef } from "react";
import { auth, authDebt } from './firebaseConfig';
import { useNavigate } from "react-router-dom";
import { isAuthenticated, getEmail, getDebtToken } from "../services/auth";
import { signInWithCustomToken } from "firebase/auth";
import Placeholder from '../assets/img/background_login.png';
import Logo from '../assets/img/logo_home.png';
import DocsImage from '../assets/img/docs.jpg';
import DebtImage from '../assets/img/debt.jpg';
import PaperImage from '../assets/img/paper.jpg';
import PfPjImage from '../assets/img/pfpj.jpg';
import PatrImage from '../assets/img/patr.jpg';
import LogoutIcon from '../assets/sair.png';
import Cookies from "js-cookie";
import moment from "moment";
import Loading from "../components/loading/loading";
import { setUserSession } from "../utils/storage";

function Dashboard() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [userToken, setUserToken] = React.useState('');
  const [acceptTerms, setAcceptTerms] = React.useState(true);
  const [services, setServices] = React.useState([
    {
      name: "debt",
      img: DebtImage,
      active: false,
      link: process.env.REACT_APP_DEBT_URL + 'login',
      token: true,
    },
    {
      name: "docs",
      img: DocsImage,
      active: false,
      description: "O InovaDOCS é a solução definitiva para simplificar a complexa gestão de documentos no setor público. Combinando tecnologias como big data, inteligência artificial e automação de processos, juntamente com nossa experiência em governança, tornamos o controle, a digitalização e a organização de documentos uma tarefa eficiente e segura.",
      link: process.env.REACT_APP_DOCS_URL
    },
    {
      name: "patr",
      img: PatrImage,
      active: false,
      description: "É a solução tecnológica adequada para gerenciar e administrar os bens móveis, possibilitando maior eficiência, agilidade e confiabilidade dos processos de gestão patrimonial, além de gerir e executar os inventários e monitoramento das movimentações de todos os bens móveis.",
      link: process.env.REACT_APP_PATR_URL
    },
    {
      name: "view",
      img: PatrImage,
      active: false,
      description: "É a solução inovadora para a gestão de dados municipais, projetada para fornecer indicadores e informações estratégicas sobre as atividades empresariais em uma determinada localização geográfica. Este produto é essencial para gestores públicos, planejadores urbanos e analistas de dados que buscam otimizar a administração municipal e promover o desenvolvimento econômico local.",
      link: "/inova-view"
    },
    {
      name: "lgpd",
      img: Placeholder,
      active: false,
      description: "Sua Rota Personalizada para Adequação à LGPD. O InovaLGPD é uma solução especializada em consultoria para a implementação e adequação de instituições à Lei Geral de Proteção de Dados (LGPD).",
    },
    {
      name: "paper",
      img: PaperImage,
      active: false,
      description: "É a solução para administrar a gestão e produção de documentos e processos administrativos de forma digital, proporcionando agilidade na elaboração, tramitação de informações/documentos e assinatura digital, bem como realizar o gerenciamento da base de dados de documentos digitalizados.",
    },
    {
      name: "pf/pj",
      img: PfPjImage,
      active: false,
      description: "É uma solução que utiliza dados de pessoas e empresas para melhorar a precisão das decisões tomadas, abordando análises detalhadas, integração de dados, automatização de processos, identificação de tendências e oportunidades de negócio, ajudando a desenvolver estratégias diferenciadas para seu negócio.",
    }]);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isStaff, setIsStaff] = React.useState(false);

  const hasVerifiedTerms = useRef(false);

  let navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      if (!isAuthenticated()) {
        navigate('/login');
        return;
      }

      setIsLoading(true);

      try {
        const response = await axios.get(`${process.env.REACT_APP_DATA_API}token/${getEmail()}`, {
          headers: {
            'Authorization': `${process.env.REACT_APP_DATA_AUTH}`,
            'Content-Type': 'application/json'
          }
        });

        Cookies.set("@inovally", JSON.stringify(
          {
            cpf: "058.255.620-10",
            email: auth.currentUser.email,
            token: response?.data?.token,
          }),
          { expires: moment().utc(true).add(24, "hours").toDate() }
        );
        setUserToken(response?.data?.token);
      } catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
          Cookies.remove("@inovally");
          Cookies.remove("@username");
          Cookies.remove("@debtToken");
          alert("Você não tem permissão para acessar este ambiente.");
          navigate('/logout');
        }
      } finally {
        setIsLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged(checkUser);
    return () => unsubscribe();
  }, [navigate]);

  const verifyIsAdmin = useCallback(async () => {
    if (Cookies.get("@inovally")) {
      const personalValues = Cookies.get("@inovally");
      if (JSON.parse(personalValues).email === "cidade_modelo@inovally.com.br" || JSON.parse(personalValues).email === "test@test.com.br") setIsAdmin(true);
      if (JSON.parse(personalValues).email === "cidade_modelo@inovally.com.br" || JSON.parse(personalValues).email === "isabel.anjos@inovally.com.br" || JSON.parse(personalValues).email === "josue.oliveira@inovally.com.br" || JSON.parse(personalValues).email === "junior.ribeiro@inovally.com.br") setIsStaff(true);
    }
  }, [setIsAdmin, setIsStaff]);

  const getUserInfo = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DEBT_API}user/info`, {
        headers: {
          'Authorization': `Bearer ${getDebtToken()}`,
          'Content-Type': 'application/json'
        }
      })

      if (response.status === 200) {
        let parsedUser = {
          "name": response.data.name,
          "email": response.data.email,
          "userRoleId": response.data.userRoleId,
          "userRole": response.data.role.name,
          "permissions": response.data.permissions,
          "cityHallId": response.data.cityHallId,
        }
        setUserSession(parsedUser);
      }

      verifyIsAdmin();

    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [verifyIsAdmin])

  const loginIntoDebt = useCallback(async (token) => {
    if (token) {
      let tokenDebt = await signInWithCustomToken(authDebt, token);
      Cookies.set("@debtToken", JSON.stringify(
        {
          token: tokenDebt?.user?.accessToken,
        }),
        { expires: moment().utc(true).add(24, "hours").toDate() });
    }
    getUserInfo();
  }, [getUserInfo])

  const verifyTerms = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DATA_API}user/${getEmail()}`, {
        headers: {
          'Authorization': `${process.env.REACT_APP_DATA_AUTH}`,
          'Content-Type': 'application/json'
        }
      });

      if (response?.data?.apps) {
        let newServices = services.map((elemento) => {
          if (response.data.apps.hasOwnProperty(elemento.name)) {
            return { ...elemento, active: response.data.apps[elemento.name] };
          }
          return elemento;
        });
        setServices(newServices);
      }
      if (response?.data?.Nome) {
        Cookies.set("@username", JSON.stringify(
          {
            name: response?.data?.Nome,
          }),
          { expires: moment().utc(true).add(24, "hours").toDate() }
        );
      }
      if (response?.data?.aceite) {
        setAcceptTerms(true)
      } else {
        setAcceptTerms(false)
      }
    } catch (err) {
      console.log(err);
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_DATA_API}token/${getEmail()}`, {
        headers: {
          'Authorization': `${process.env.REACT_APP_DATA_AUTH}`,
          'Content-Type': 'application/json'
        }
      });

      Cookies.set("@inovally", JSON.stringify(
        {
          cpf: "058.255.620-10",
          email: auth.currentUser.email,
          token: response?.data?.token,
        }),
        { expires: moment().utc(true).add(24, "hours").toDate() }
      );
      loginIntoDebt(response?.data?.token);
    } catch (err) {
      console.log(err);
    }
  }, [services, loginIntoDebt]);

  useEffect(() => {
    if (hasVerifiedTerms.current) return;
    hasVerifiedTerms.current = true;

    verifyTerms();
  }, [verifyTerms]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) navigate('/logout');
    });

    return () => unsubscribe();
  }, [navigate]);

  return (
    <>
      {!acceptTerms && <Terms />}
      <Loading isActive={isLoading} />
      <Container>
        <HeaderWrap>
          <div className="logo">
            <img src={Logo} alt="inovally" />
          </div>
          <div>
            {isStaff &&
              <div onClick={() => navigate('/operations')}>
                <span>
                  Interno
                </span>
              </div>
            }
            {isAdmin &&
              <div onClick={() => navigate('/settings')}>
                <img src="./settings.svg" height={25} width={25} alt="configurações" />
                <span>
                  Configurações
                </span>
              </div>
            }
            <div onClick={() => navigate('/logout')} className="menu">
              <img src={LogoutIcon} alt="sair" />
              <span>
                Sair
              </span>
            </div>
          </div>
        </HeaderWrap>
        <Title>Selecione a aplicação Inovally</Title>
        <Options>
          {services.map((service) => {
            return (service.active ?
              <a href={`${service.link}?token=${userToken}`} key={`service_${service.name}`}>
                <Option className="option" key={`service-${service.name}`} $bgImage={service.img}>
                  <div className="name">
                    <img src={Prename} alt="inovally" />
                    <h3>{service.name}</h3>
                  </div>
                </Option>
              </a>
              :
              <Option className="option" key={`service-${service.name}`} $bgImage={service.img}>
                <div className="name">
                  <img src={Prename} alt="inovally" />
                  <h3>{service.name}</h3>
                </div>
                <div className="description">
                  <p>{service.description} </p>
                </div>
              </Option>
            )
          })}
        </Options>
      </Container>
    </>
  );
}

export default Dashboard;