import Cookies from "js-cookie";

export const isAuthenticated = () => {
    // const cookies = new Cookies();

    try {
        const user = Cookies.get("@innovally");

        if (user) {
            const data = JSON.parse(user);

            if (data?.token) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const getToken = () => {
    const user = Cookies.get("@innovally");

    if (user) {
        const data = JSON.parse(user);

        return data?.token ?? "";
    } else return "";
};


export const getEmail = () => {
    const user = Cookies.get("@innovally");

    if (user) {
        const data = JSON.parse(user);

        return data?.email ?? "";
    } else return "";
};

export const getDebtToken = () => {
    const debt = Cookies.get("@debtToken");
    if(debt) {
        const data = JSON.parse(debt);
        return data?.token ?? process.env.REACT_APP_DEBT_API;
    }
}